import { CommonModule } from "@angular/common";
import {
	Component,
	EventEmitter,
	OnInit,
	Output,
	Input,
	WritableSignal,
	signal,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import {
	AcademyCard,
	Sport,
} from "@sportyano/core/models/academy-card/academy-card.model";
import { profileParams } from "@sportyano/core/models/account/profile";
import { City } from "@sportyano/core/models/cities/cities.model";
import { Countries } from "@sportyano/core/models/countries/countries.model";
import { permission } from "@sportyano/core/models/permissions/permission";
import { Property } from "@sportyano/core/models/sports-human/sports-human.model";
import { AcademyService } from "@sportyano/core/services/academy/academies/academy.service";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { GeneralService } from "@sportyano/core/services/generals/general.service";
import { DropdownModule } from "primeng/dropdown";
import { MultiSelectModule } from "primeng/multiselect";
import { SliderModule } from "primeng/slider";
import { TreeSelectModule } from 'primeng/treeselect';
import {TreeNode} from 'primeng/api';
import {
	ReactiveFormsModule,
} from "@angular/forms";

interface Filter {
	sort: SortOptions;
	search: string;
	SearchByCity: string;
	SearchByCountry: number;
	selectConutry: number[];
	selectSports: string;
	selectAcademy: string;
	contract?: boolean;
	filterSport?: string;
	selectPosition?: number | null;
	position: string;
	age_from: number | undefined;
	age_to: number | undefined;
	nationalities?: number[];
}

interface SortOptions {
	name: string;
	id: number;
	isAsc: boolean;
	corrctName?: string;
}

@Component({
	selector: "app-advanced-side-filteration",
	templateUrl: "./advanced-side-filteration.component.html",
	styleUrls: ["./advanced-side-filteration.component.scss"],
	standalone:true,
	imports:[
		CommonModule,
		FormsModule,
		TranslateModule,
		MultiSelectModule,
		DropdownModule,
		TreeSelectModule,
		SliderModule,
		ReactiveFormsModule,
	]
})
export class AdvancedSideFilterationComponent implements OnInit {
	showTooltip = false;
	@Input() filterType: string;
	@Output() filterOption = new EventEmitter<any>();
	@Output() resetFilter = new EventEmitter<void>();
	public profileParam: WritableSignal<any> = signal<any>({});
	public sports: WritableSignal<Sport[]> = signal<Sport[]>([]);
	readonly minimumAge: number = 1980;
	readonly maximumAge: number = 2020;
	chosenMinimumAge: number = 1960;
	chosenMaximumAge: number = new Date().getFullYear() - 2;
	ageRange: number[] = [this.chosenMaximumAge, this.chosenMinimumAge];
	permission = permission;
	countries: Countries[] = [];
	cities: any[] = [];
	// sports: Sport[] = [];
	selectedCityNode: any = null; 

	academies: AcademyCard[] = [];
	filterSport: Property[] = [];
	positions: any[] = [];
	egyptId: number | undefined;
	sorting: SortOptions[] = [
		{
			id: 1,
			name: this.translate.instant("date"),
			isAsc: true,
			corrctName: "id",
		},
		{
			id: 2,
			name: this.translate.instant("name"),
			isAsc: true,
			corrctName: "name",
		},
	];

	filter: Filter = {
		sort: { name: "", id: 0, isAsc: true, corrctName: "" },
		search: "",
		SearchByCity: "",
		SearchByCountry: 0,
		selectConutry: [],
		selectSports: "",
		selectAcademy: "",
		contract: false,
		filterSport: "",
		selectPosition: null,
		position: "",
		age_from: undefined,
		age_to: undefined,
	};

	constructor(
		private generalService: GeneralService,
		private academyService: AcademyService,
		private translate: TranslateService,
		private _activatedRoute: ActivatedRoute,
		private auth: AuthService
	) {}

	ngOnInit(): void {
		this.sorting = [
			{
				id: 1,
				name: this.translate.instant("date"),
				isAsc: true,
				corrctName: "id",
			},
			{
				id: 2,
				name: this.translate.instant("name"),
				isAsc: true,
				corrctName: "name",
			},
		];
		this._listenQueryParams();
		this.fetchSports();
		this.fetchCountries();
	}
	onAgeChange(event: any): void {
		// Update filter with new age range
		this.filter.age_from = this.ageRange[0];
		this.filter.age_to = this.ageRange[1];
		this.sendFilter();
	}
	private _listenQueryParams() {
		this._activatedRoute.queryParams.subscribe({
			next: (params) => {
				this.profileParam.set(params);
				// console.log(this.profileParam()['profile'],'profileParam');
			},
		});
	}
	toggleSort(item: SortOptions) {
		item.isAsc = !item.isAsc;
		this.filter.sort = item;
		this.sendFilter();
	}
	selectedCountryName: string | undefined;
	private fetchCountries() {
		// this.generalService.getCountries().subscribe((data) => {
		switch (this.profileParam()["profile"]) {
			case profileParams.players:
				this.generalService
					.getCountriesFilled(profileParams.players)
					.subscribe((data) => {
						if (data) {
							this.countries = data.records.sort(
								(a: any, b: any) => a.name.localeCompare(b.name)
							);
						}
						this.sendFilter();
					});
				break;
			case profileParams.academies:
				this.generalService.getCountries().subscribe((data) => {
					if (data) {
						const egypt = data.records.find(
							(a: any) => a.id === 56
						);
						this.countries = data.records.sort((a: any, b: any) =>
							a.name.localeCompare(b.name)
						);
						if (egypt) {
							this.selectedCountryName = egypt.name;
						}
						this.sendFilter();
					}
				});
				break;
			case profileParams.playgrounds:
				this.generalService.getCountries().subscribe((data) => {
					if (data) {
						const egypt = data.records.find(
							(a: any) => a.id === 56
						);
						this.countries = data.records.sort((a: any, b: any) =>
							a.name.localeCompare(b.name)
						);
						if (egypt) {
							this.selectedCountryName = egypt.name;
						}
						this.sendFilter();
					}
				});
				break;
			default:
				this.generalService
					.getCountriesFilled(profileParams.players)
					.subscribe((data) => {
						if (data) {
							this.countries = data.records.sort(
								(a: any, b: any) => a.name.localeCompare(b.name)
							);
							// this.getCity('');
							this.sendFilter();
						}
					});
				break;
		}

		// this.getCity(56);
	}

	// private fetchSports() {
	// 	this.generalService.getSports().subscribe((data) =>

	// 		this.sports = data.records.sort((a,b)=>a.name.localeCompare(b.name)));
	// 		console.log(this.sports)
	// }

	private fetchSports() {
		switch (this.profileParam()["profile"]) {
			case profileParams.players:
				this.generalService
					.sportsOnlyUsed(profileParams.players)
					.subscribe({
						next: (data: any) => {
							// console.log(data.sports, 'player');

							this.sports.set(
								data.sports.sort((a: any, b: any) =>
									a.name.localeCompare(b.name)
								)
							);
						},
					});
				break;
			case profileParams.academies:
				this.generalService
					.sportsOnlyUsed(profileParams.academies)
					.subscribe({
						next: (data: any) => {
							// console.log(data.sports, 'academy');

							this.sports.set(
								data.sports.sort((a: any, b: any) =>
									a.name.localeCompare(b.name)
								)
							);
						},
					});
				break;
			case profileParams.playgrounds:
				this.generalService
					.sportsOnlyUsed(profileParams.playgrounds)
					.subscribe({
						next: (data: any) => {
							// console.log(data.sports, 'playground');

							this.sports.set(
								data.sports.sort((a: any, b: any) =>
									a.name.localeCompare(b.name)
								)
							);
						},
					});
				break;
			case profileParams.videos:
				this.generalService
					.sportsOnlyUsed(profileParams.players)
					.subscribe({
						next: (data: any) => {
							this.sports.set(
								data.sports.sort((a: any, b: any) =>
									a.name.localeCompare(b.name)
								)
							);
						},
					});
				break;
			default:
				this.generalService.getSports().subscribe({
					next: (data: any) => {
						this.sports.set(
							data.records.sort((a: any, b: any) =>
								a.name.localeCompare(b.name)
							)
						);
					},
				});
		}
	}

	getSportFilter(event: any): void {
		if (this.filterType === permission.player) {
			this.generalService
				.getSportsHumen(event.value)
				.subscribe((data) => {
					this.filterSport =
						data.records.find((ele) => ele.label === "position")
							?.properties || [];
				});
		}
		this.fetchPositions(event.value);
	}

	private fetchPositions(sportId: number): void {
		// clear position before filter
		this.filter.selectPosition = null;
		this.generalService.getSportsPositions(sportId).subscribe((data) => {
			this.positions = data.sort((a: any, b: any) =>
				a.name.localeCompare(b.name)
			);
			setTimeout(() => this.sendFilter(), 1000);
		});
	}

	getCity(id: number): void {
		this.generalService.getCities(id).subscribe((data) => {
			if (this.filterType == permission.player || this.filterType == 'media'){
				this.cities = data.records.sort((a: any, b) => a.name.localeCompare(b.name))
			} else {
				this.cities = this.convertCitiesToTree(data.records);
			}
			})
	}
	private convertCitiesToTree(cities: City[]): TreeNode[] {
			return cities.map(city => ({
				key: city.id.toString(),
				label: city.name, // Arabic or English name is returned from backend
				data: city,
				expanded:true,
				styleClass: city.towns && city.towns.length > 0 ? 'has-children' : '', // Add custom styling to disable towns with children
				// selectable: city.towns.length === 0, // Only selectable if it has no towns
				children: city.towns?.map(town => ({
					key: town.id.toString(),
					label: town.name,
					data: town,
					styleClass: 'children',
					selectable: true // Always selectable
				})) || []
			}));
	}

	onCitySelect(event: any): void {
		if (event && event.node) {
			this.filter.SearchByCity = event.node.key; // Store city ID
			this.sendFilter(); // Call filter function
		}
	}


	private fetchDataAcademy(): void {
		// console.log(this.filter.selectAcademy);

		this.generalService
			.getAcademyListwithParam(this.filter)
			.subscribe((data) => {
				// window.alert(this.filter.selectAcademy)
				this.academies = data.data;
				// console.log(this.academies);
			});
	}

	sendFilter(): void {
		const filterObj: any = {
			search: this.filter.search || "",
			sort_by: this.filter.sort.corrctName,
			sort_direction: (this.filterType === 'media' || this.filterType === 'player')
			? (this.filter.sort.isAsc ? "desc" : "asc")
			: (this.filter.sort.isAsc ? "asc" : "desc"),
			contract: this.filter.contract,
			nationalities: this.filter.selectConutry || [],
			country_id: this.filter.SearchByCountry || 0,
			city_id: this.filter.SearchByCity || 0,
			sport_id: this.filter.selectSports || 0,
			academy_id: this.filter.selectAcademy || 0,
			property_id: this.filter.filterSport || 0,
			age_from: this.filter.age_from,
			age_to: this.filter.age_to,
		};
		// console.log('Filter Object:', filterObj);
		// console.log('Selected Academy ID:', this.filter.selectAcademy);

		// Add the position filter parameter conditionally
		if (this.filter.selectPosition) {
			filterObj.sport_option_position_id = this.filter.selectPosition;
		}
		this.filterOption.emit(filterObj);
	}

	public onCountryChange(countryId: number): void {
		this.getCity(countryId);
		this.sendFilter();
	}

	clearTreeSelect() {
		this.cities = [];
		this.selectedCityNode = null;
	}

	reset(): void {
		if (this.filterType != permission.player && this.filterType != 'media'){
			this.clearTreeSelect();
		}
		this.filter = {
			sort: { name: "", id: 0, isAsc: true, corrctName: "" },
			search: "",
			SearchByCity: "",
			SearchByCountry: 0,
			selectConutry: [],
			selectSports: "",
			selectAcademy: "",
			contract: false,
			filterSport: "",
			selectPosition: null,
			position: "",
			age_from: undefined,
			age_to: undefined,
		};
		this.ageRange = [this.chosenMaximumAge, this.chosenMinimumAge];
		this.resetFilter.emit();
	}
}
